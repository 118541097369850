import axios from "axios";
import {
  User,
  UserMeta,
  CurrentUser,
  ResourceSearchResult,
  IndigenousCommunity,
  Vendor,
  Person,
  SearchParams,
  ReferenceData,
  Project,
  SearchSubProjects,
  SearchChangeOrders,
  Spend,
  SearchPurchaseOrders,
  GeneralSearchCriteria,
  SearchExpenses,
  Benefit,
  BenefitState,
  ProjectPath,
  SearchIndigenousEmployments,
  TieredReferenceData,
  Customer,
  CategoryReferenceData,
  ShortRecord,
  UserAccountSettings,
  Expense,
  DiversityReferenceData,
  AffiliatedVendorsMeta,
  PayeeSearchCriteria,
  SearchFacetDetail,
  Employment,
  AggSearchCriteria,
  SearchFilterDetail,
  ProjectSearchCriteria,
  EIRData,
  ExpenseAggregate,
  DataServiceAggregateQuery,
  EmploymentAggregate,
  EIREmployeeAnalysis,
  EIRCrossAnalysis,
} from "./models";
import { EmptyGeneralSearchCriteria } from "./models-empty";
import * as _ from "lodash";
import * as testData from "../lib/test-data";
import { base64Encode } from "@/lib/reports";
import qs from "qs";

function formatSearchParams(params: SearchParams): string {
  return "?page=" + params.page + "&ipp=" + params.ipp + "&sortby=" + params.sortby + "&sortdir=" + params.sortdir + "&terms=" + params.terms;
}

function addFieldValueParam(fieldName: string, fieldValues: string[] | string): string {
  if (fieldValues) {
    if (Array.isArray(fieldValues)) {
      let params: string = "";
      for (const fieldValue of fieldValues) {
        params += fieldValue !== "" ? "&field-value=" + fieldName + ":" + fieldValue : "";
      }
      return params;
    } else {
      return fieldValues !== "" ? "&field-value=" + fieldName + ":" + fieldValues : "";
    }
  } else {
    return "";
  }
}

function addIdsValueParam(fieldValues: string[]) {
  return fieldValues.length > 0 ? "&ids=" + fieldValues.join(",") : "";
}

function addNamedArrayParam(paramValues: string[] | string | ShortRecord[] | ShortRecord, paramName: string) {
  if (paramValues) {
    if (Array.isArray(paramValues)) {
      if (paramValues && paramValues.length > 0) {
        const paramValueList: string = typeof paramValues[0] === "string" ? paramValues.join(",") : (paramValues as ShortRecord[]).map((item) => item.identifier).join(",");
        return "&" + paramName + "=" + paramValueList;
      } else {
        return "";
      }
    } else {
      return "&" + paramName + "=" + (typeof paramValues === "string" ? paramValues : (paramValues as ShortRecord).identifier);
    }
  } else {
    return "";
  }
}

function addNameParamArrayNoCommas(paramValues: string[] | string | ShortRecord[] | ShortRecord, paramName: string) {
  if (paramValues) {
    if (Array.isArray(paramValues)) {
      if (paramValues && paramValues.length > 0) {
        return typeof paramValues[0] === "string"
          ? (paramValues as string[]).map((item) => "&" + paramName + "=" + item).join("")
          : (paramValues as ShortRecord[]).map((item) => "&" + paramName + "=" + item.identifier).join("");
      } else {
        return "";
      }
    } else {
      return "&" + paramName + "=" + (typeof paramValues === "string" ? paramValues : (paramValues as ShortRecord).identifier);
    }
  } else {
    return "";
  }
}

function addDateRangeParams(dateFrom: string, dateTo: string) {
  return (dateFrom !== "" ? "&effective-from-date=" + dateFrom : "") + (dateTo !== "" ? "&effective-to-date=" + dateTo : "");
}

function addFacetsSearchCriteria(searchFacetDetails: SearchFacetDetail[]) {
  let url: string = "";
  if (searchFacetDetails !== undefined) {
    for (const searchFacetDetail of searchFacetDetails) {
      for (const facet of searchFacetDetail.facets) {
        if (facet.isSelected) {
          url += addFieldValueParam(facet.path, facet.value);
        }
      }
    }
  }
  return url;
}

function addFiltersSearchCriteria(searchFilterDetails: SearchFilterDetail[]) {
  let url: string = "";
  if (searchFilterDetails !== undefined) {
    for (const searchFilterDetail of searchFilterDetails) {
      for (const filter of searchFilterDetail.filters) {
        if (filter.isSelected) {
          if (searchFilterDetail.qsParam !== "") {
            url += addNameParamArrayNoCommas(filter.values, searchFilterDetail.qsParam);
          } else if (searchFilterDetail.fieldValueParam !== "") {
            url += addFieldValueParam(searchFilterDetail.fieldValueParam, filter.values);
          }
        }
      }
    }
  }
  return url;
}

function addGeneralSearchCriteria(searchCriteria: GeneralSearchCriteria) {
  let url: string = addDateRangeParams(searchCriteria.dateFrom, searchCriteria.dateTo);
  url += addNamedArrayParam(searchCriteria.projects, "project");
  url += addNamedArrayParam(searchCriteria.organizations, "community");
  url += addNamedArrayParam(searchCriteria.clients, "vendor");
  url += addNamedArrayParam(searchCriteria.beneficiaries, "beneficiary");
  url += addFiltersSearchCriteria(searchCriteria.filters);
  url += addFacetsSearchCriteria(searchCriteria.facets);
  return url;
}

function addAggSearchCriteria(searchCriteria: AggSearchCriteria): string {
  let uri: string = "";
  if (searchCriteria.hasPayee !== "") {
    uri += "&agg-has-payee=" + searchCriteria.hasPayee;
  }
  if (searchCriteria.hasPayer !== "") {
    uri += "&agg-has-payer=" + searchCriteria.hasPayer;
  }
  if (searchCriteria.hasProject !== "") {
    uri += "&agg-has-project=" + searchCriteria.hasProject;
  }
  if (searchCriteria.hasBeneficiary !== "") {
    uri += "&agg-has-beneficiary=" + searchCriteria.hasBeneficiary;
  }
  if (searchCriteria.isClient) {
    uri += "&agg-client=true";
  }
  return uri;
}

function addProjectSearchCriteria(searchCriteria: ProjectSearchCriteria) {
  let uri: string = "";
  uri += addFieldValueParam("common.status", searchCriteria.statuses);
  uri += addFieldValueParam("project.creatorVendor", searchCriteria.clients);
  uri += addFieldValueParam("project.indigenousAffiliation", searchCriteria.organizations);
  return uri;
}

function addPayeeSearchCriteria(searchCriteria: PayeeSearchCriteria) {
  let uri: string = "";
  if (searchCriteria.payeesOf !== "") {
    uri += "&payees-of=" + searchCriteria.payeesOf;
  }
  if (searchCriteria.clientsOf !== "") {
    uri += "&clients-of=" + searchCriteria.clientsOf;
  }
  if (searchCriteria.subVendorsOf !== "") {
    uri += "&subvendors-of=" + searchCriteria.subVendorsOf;
  }
  if (searchCriteria.payersOf !== "") {
    uri += "&payers-of=" + searchCriteria.payersOf;
  }
  return uri;
}

function addFacetBundle(bundleName: string) {
  return "&facet=" + bundleName;
}

function addFacet(name: string, path: string, displayName: string) {
  return "&facet=" + name + ":" + path + ":" + displayName;
}

function addDSQueryParams(query: DataServiceAggregateQuery) {
  let uri: string = "";
  if (query.groupBy) {
    uri += `&group-by=${query.groupBy}`;
  }
  if (query.dateFrom) {
    uri += `&date-from=${query.dateFrom}`;
  }
  if (query.dateTo) {
    uri += `&date-to=${query.dateTo}`;
  }
  if (query.constraints) {
    for (const constraint of Object.keys(query.constraints)) {
      uri += `&q=${constraint};${query.constraints[constraint]}`;
    }
  }

  return uri;
}

export const queryServicebaseUrl: string = process.env.VUE_APP_QS_URL;
export const reportingServicebaseUrl: string = process.env.VUE_APP_RS_URL;
export const dataServicebaseUrl: string = process.env.VUE_APP_DS_URL;

export const publicApi = axios.create({
  baseURL: queryServicebaseUrl,
  withCredentials: true,
});

export const reportsApi = axios.create({
  baseURL: reportingServicebaseUrl,
  withCredentials: false,
});

export const dataApi = axios.create({
  baseURL: dataServicebaseUrl,
  withCredentials: false,
});

export async function getShortList(
  listType: string,
  anyRecord: boolean = false,
  whereClauses: Array<[string, string[]]> = [],
  noGeneral: boolean = false,
  duplicateRecordProperty: string = "",
  excludeIds: string[] = [],
): Promise<ResourceSearchResult> {
  let uri: string = "/record/short?type=" + listType + "&ipp=10000000&sortby=default&sortdir=asc";
  if (anyRecord === true) {
    uri += "&any-record=true";
  }
  if (noGeneral === true) {
    uri += "&no-general=true";
  }
  for (const whereClause of whereClauses) {
    uri += addFieldValueParam(whereClause[0], whereClause[1]);
  }
  const response = await publicApi.get(uri);
  return removeExcludedItems(addDuplicateRecordPropertyToDisplayName(response.data as ResourceSearchResult, duplicateRecordProperty), excludeIds);
}

function removeExcludedItems(response: ResourceSearchResult, excludeIds: string[]): ResourceSearchResult {
  if (excludeIds.length > 0) {
    const excludedIndex: number = (response.searchResults.results as ShortRecord[]).findIndex((x: ShortRecord) => excludeIds.includes(x.identifier as string));
    if (excludedIndex > -1) {
      response.searchResults.results.splice(excludedIndex, 1);
    }
  }
  return response;
}

function addDuplicateRecordPropertyToDisplayName(response: ResourceSearchResult, duplicateRecordProperty: string): ResourceSearchResult {
  if (duplicateRecordProperty !== "") {
    for (const result of response.searchResults.results) {
      if (result[duplicateRecordProperty]) {
        (result as ShortRecord).displayName = (result as ShortRecord).displayName + " (" + result[duplicateRecordProperty] + ")";
      }
    }
  }
  return response;
}
/*
export async function getDiversityReferenceData(): Promise<DiversityReferenceData> {
    const response = await publicApi.get('/record/refdata/diversity-declarations?sort=true');
    return (response.data as DiversityReferenceData);
}
*/
export async function getDiversityReferenceData(): Promise<DiversityReferenceData> {
  return testData.getDiversityRefData();
}

export async function getReferenceData(refDataType: string): Promise<ReferenceData> {
  const response = await publicApi.get("/record/refdata/" + refDataType + "?sort=true");
  return response.data as ReferenceData;
}

export async function getTieredReferenceData(refDataType: string): Promise<TieredReferenceData> {
  const response = await publicApi.get("/record/refdata/" + refDataType + "?sort=true");
  return response.data as TieredReferenceData;
}

export async function getCategoryReferenceData(refDataType: string): Promise<CategoryReferenceData> {
  const response = await publicApi.get("/record/refdata/" + refDataType + "?sort=true");
  return response.data as CategoryReferenceData;
}

export async function getUser(userId: string): Promise<User> {
  const response = await publicApi.get("/users/" + userId);
  return response.data as User;
}

export async function getUsers(params: SearchParams): Promise<User[]> {
  const response = await publicApi.get("/users" + formatSearchParams(params));
  return response.data as User[];
}

export async function getIndigenousCommunity(id: string, resolveShort: boolean = false): Promise<IndigenousCommunity> {
  let uri: string = "/record/ic/id/" + id;
  uri += resolveShort ? "?resolve-short=true" : "";
  const response = await publicApi.get(uri);
  return response.data as IndigenousCommunity;
}

export async function getIndigenousCommunityClients(id: string, resolveShort: boolean = false): Promise<ResourceSearchResult> {
  let uri: string = "/record/ic/id/" + id + "/client";
  uri += resolveShort ? "?resolve-short=true" : "";
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getIndigenousCommunities(params: SearchParams, id: string = "", vendorId: string = ""): Promise<ResourceSearchResult> {
  let uri: string = "/record/ic" + formatSearchParams(params);
  uri += id !== "" ? addFieldValueParam("identifier", [id]) : "";
  uri += vendorId !== "" ? "&vendor=" + vendorId : "";
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getIndigenousCommunityContacts(id: string): Promise<ResourceSearchResult> {
  const uri: string = "/record/ic/id/" + id + "/contact?ipp=999999";
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function createIndigenousCommunity(newIg: IndigenousCommunity): Promise<any> {
  const response = await publicApi.post("/record/ic", newIg);
  return response.data;
}

export async function updateIndigenousCommunity(editedId: string, editedIg: IndigenousCommunity): Promise<any> {
  const response = await publicApi.put("/record/ic/id/" + editedId, editedIg);
  return response.data;
}

export async function createExpense(newExpense: Expense): Promise<any> {
  const response = await publicApi.post("/record/expense", newExpense);
  return response.status === 201 ? response.headers["x-id"] : "";
}

export async function updateExpense(editedId: string, editedExpense: Expense): Promise<any> {
  const response = await publicApi.put("/record/expense/id/" + editedId, editedExpense);
  return response.data;
}

export async function getExpense(id: string): Promise<Expense> {
  const response = await publicApi.get("/record/expense/id/" + id);
  return response.data as Expense;
}

export async function setProjectVendor(projectId: string, affiliatedVendor: AffiliatedVendorsMeta): Promise<any> {
  let uri: string = "/record/project/id/" + projectId + "/vendor/id/" + affiliatedVendor.vendorId;
  uri += "?is-local=" + (affiliatedVendor.isLocal ? "true" : "false");
  uri += affiliatedVendor.indigenousPartner !== undefined && (affiliatedVendor.indigenousPartner as string) !== "" ? "&partner=" + affiliatedVendor.indigenousPartner : "";
  const response = await publicApi.put(uri);
  return response.data;
}

export async function deleteProjectVendor(projectId: string, vendorId: string): Promise<any> {
  const response = await publicApi.delete("/record/project/id/" + projectId + "/vendor/id/" + vendorId);
  return response.data;
}

export async function getProjectPayees(
  params: SearchParams,
  id: string,
  searchCriteria: GeneralSearchCriteria,
  aggSearchCriteria: AggSearchCriteria,
  payeeSearchCriteria: PayeeSearchCriteria,
): Promise<ResourceSearchResult> {
  let uri: string = "/record/project/id/" + id + "/payee" + formatSearchParams(params);
  uri += addPayeeSearchCriteria(payeeSearchCriteria);
  uri += addAggSearchCriteria(aggSearchCriteria);
  uri += addGeneralSearchCriteria(searchCriteria);
  uri += addFacet("proxyPayeeFor", "vendor.proxyPayeeFor", "Indigenous Community");
  uri += addFacet("category", "vendor.defaultExpenseClass", "Category");
  uri += addFacetBundle("_payeeDiversity");
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function createVendor(newVendor: Vendor): Promise<any> {
  const response = await publicApi.post("/record/vendor", newVendor);
  return response.status === 201 ? response.headers["x-id"] : "";
}

export async function updateVendor(editedId: string, editedVendor: Vendor, etag: string): Promise<any> {
  const headers = { "if-match": etag };
  const config = { headers };
  const response = await publicApi.put("/record/vendor/id/" + editedId, editedVendor, config);
  return response.data;
}

export async function getVendor(id: string): Promise<Vendor> {
  const response = await publicApi.get("/record/vendor/id/" + id);
  return response.data as Vendor;
}

export async function getVendorsViaRecordRequest(): Promise<Vendor[]> {
  const uri: string = "/record/short?type=Vendor&ipp=10000000&sortby=default&sortdir=asc&no-search-decorate=true";
  const response = await publicApi.get(uri);
  return response.data.searchResults.results as Vendor[];
}

export async function getVendors(params: SearchParams, id: string = "", searchCriteria: GeneralSearchCriteria = EmptyGeneralSearchCriteria, payeesOf: string = ""): Promise<ResourceSearchResult> {
  let uri: string = "/record/vendor" + formatSearchParams(params);
  uri += id !== "" ? addFieldValueParam("identifier", [id]) : "";
  uri += payeesOf ? "&payees-of=" + payeesOf : "";
  uri += addDateRangeParams(searchCriteria.dateFrom, searchCriteria.dateTo);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getCommunityClientProjects(vendorId: string, communityId: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/project?resolve-short=true&ipp=999999";
  uri += addFieldValueParam("project.creatorVendor", [vendorId]);
  uri += addFieldValueParam("project.indigenousAffiliation", [communityId]);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getCommunitySubVendorProjects(vendorId: string, communityId: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/project?resolve-short=true&ipp=999999";
  uri += addFieldValueParam("project.ownerVendor", [vendorId]);
  uri += addFieldValueParam("project.indigenousAffiliation", [communityId]);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getPayeeProjects(params: SearchParams, aggSearchCriteria: AggSearchCriteria, vendorId: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/payee/id/" + vendorId + "/project" + formatSearchParams(params) + "&resolve-short=true";
  uri += addAggSearchCriteria(aggSearchCriteria);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getClientProjects(params: SearchParams, aggSearchCriteria: AggSearchCriteria, vendorId: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/client/id/" + vendorId + "/project" + formatSearchParams(params) + "&resolve-short=true";
  uri += addAggSearchCriteria(aggSearchCriteria);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function createCustomer(newCustomer: Customer): Promise<any> {
  const response = await publicApi.post("/record/customer", newCustomer);
  return response.data;
}

export async function updateCustomer(editedId: string, editedCustomer: Customer): Promise<any> {
  const response = await publicApi.put("/record/customer/id/" + editedId, editedCustomer);
  return response.data;
}

export async function getCustomer(id: string): Promise<Customer> {
  const response = await publicApi.get("/record/customer/id/" + id);
  return response.data as Customer;
}

export async function getCustomers(params: SearchParams): Promise<ResourceSearchResult> {
  const response = await publicApi.get("/record/customer" + formatSearchParams(params) + "&resolve-short=customer-vendor");
  return response.data as ResourceSearchResult;
}

export async function getPerson(id: string): Promise<Person> {
  const response = await publicApi.get("/record/person/id/" + id);
  return response.data as Person;
}

export async function getPersons(params: SearchParams): Promise<ResourceSearchResult> {
  const response = await publicApi.get("/record/person" + formatSearchParams(params) + "&resolve-short=has-indigenous-affiliation,member-of");
  return response.data as ResourceSearchResult;
}

export async function createPerson(newPerson: Person, createAUser: boolean): Promise<any> {
  const response = await publicApi.post("/record/person" + (createAUser ? "?force-user=true" : ""), newPerson);
  return response.data;
}

export async function createUser(newUser: UserMeta, personId?: string): Promise<any> {
  const response = await publicApi.post("/record/user/person/" + personId, { user: newUser });
  return response.data;
}

export async function createContact(newContact: Person): Promise<any> {
  const response = await publicApi.post("/record/person/contact", newContact);
  return response.status === 201 ? response.headers["x-id"] : "";
}

export async function getPartnerContacts(partnerId: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/person/contact?resolve-short=true&ipp=999999";
  uri += addFieldValueParam("contactFor", [partnerId]);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getContacts(): Promise<ResourceSearchResult> {
  const uri: string = "/record/person/contact?resolve-short=true&ipp=999999";
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function updatePerson(editedId: string, editedPerson: Person): Promise<any> {
  const response = await publicApi.put("/record/person/id/" + editedId, editedPerson);
  return response.data;
}

export async function getCurrentUser(): Promise<CurrentUser> {
  const response = await publicApi.get("/record/user/current");
  return response.data;
}

export async function setCurrentOrg(orgId: string): Promise<any> {
  const response = await publicApi.put("/record/user/current/org/" + orgId);
  return response.data;
}

export function getLoginRedirect(): string {
  return queryServicebaseUrl + "/oauth/login/auth0";
}

export function getLogoutRedirect(): string {
  return queryServicebaseUrl + "/oauth/logout";
}

export async function performLogin(username: string, password: string): Promise<string> {
  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", password);
  const response = await publicApi.post(getLoginRedirect(), params);
  return response.status === 205 ? response.headers["location"] : "";
}

export async function searchIndigenousEmploymentsByWeek(params: SearchParams, searchCriteria: SearchIndigenousEmployments): Promise<ResourceSearchResult> {
  const uri: string = "/record/employment/week/project/" + searchCriteria.project + formatSearchParams(params) + "&resolve-short=true";
  // uri += addFieldValueParam('hasBeneficiary', searchCriteria.organizations);
  // uri += addDateRangeParams(searchCriteria.dateFrom, searchCriteria.dateTo);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchIndigenousEmployments(params: SearchParams, searchCriteria: SearchIndigenousEmployments): Promise<ResourceSearchResult> {
  let uri: string = "/record/employment/" + formatSearchParams(params) + "&resolve-short=true";
  uri += addDateRangeParams(searchCriteria.dateFrom, searchCriteria.dateTo);
  uri += addFieldValueParam("payment.hasProject", [searchCriteria.project]);
  uri += addFieldValueParam("payment.hasBeneficiary", searchCriteria.organizations);
  uri += addFieldValueParam("employment.occupation", searchCriteria.jobFocuses);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchIndigenousEmploymentsByDate(params: SearchParams, searchCriteria: GeneralSearchCriteria): Promise<ResourceSearchResult> {
  let uri: string = "/record/employment/date/project/" + searchCriteria.projects[0] + formatSearchParams(params) + "&resolve-short=true";
  uri += addDateRangeParams(searchCriteria.dateFrom, searchCriteria.dateTo);
  if (searchCriteria) {
    uri += addGeneralSearchCriteria(searchCriteria);
  }
  uri += addFieldValueParam("occupation", searchCriteria.jobFocuses);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getEmployment(id: string, resolveShort: boolean): Promise<Employment> {
  const response = await publicApi.get("/record/employment/id/" + id + (resolveShort ? "?resolve-short=true" : ""));
  return response.data as Employment;
}

export async function getEmployments(projectId: string, week: string, resolveShort: boolean): Promise<Employment[]> {
  let uri: string = "/record/employment?";
  uri += addFieldValueParam("hasProject", [projectId]);
  uri += addFieldValueParam("employmentWeek", [week]);
  uri += resolveShort ? "&resolve-short=true" : "";
  const response = await publicApi.get(uri);
  const results = (response.data as ResourceSearchResult).searchResults.results as Employment[];
  // Delete index property.
  for (const result of results) {
    delete result.index;
  }
  return (response.data as ResourceSearchResult).searchResults.results as Employment[];
}

export async function createEmployment(newIndigenousEmployment: Employment): Promise<any> {
  const response = await publicApi.post("/record/employment", newIndigenousEmployment);
  return response.data;
}

export async function updateEmployment(editedId: string, editedIndigenousEmployment: Employment): Promise<any> {
  const response = await publicApi.put("/record/employment/id/" + editedId, editedIndigenousEmployment);
  return response.data;
}

export async function searchPayees(
  params: SearchParams,
  searchCriteria: GeneralSearchCriteria,
  aggSearchCriteria: AggSearchCriteria,
  payeeSearchCriteria: PayeeSearchCriteria,
  addFacets: boolean = true,
  whereClauses: Array<[string, string[]]> = [],
): Promise<ResourceSearchResult> {
  let uri: string = "/record/vendor/" + formatSearchParams(params) + "&resolve-short=true";
  uri += addPayeeSearchCriteria(payeeSearchCriteria);
  uri += addGeneralSearchCriteria(searchCriteria);
  uri += addAggSearchCriteria(aggSearchCriteria);
  for (const whereClause of whereClauses) {
    uri += addFieldValueParam(whereClause[0], whereClause[1]);
  }
  if (addFacets) {
    uri += addFacet("proxyPayeeFor", "vendor.proxyPayeeFor", "Indigenous Community");
    uri += addFacet("category", "vendor.defaultExpenseClass", "Category");
    uri += addFacetBundle("_payeeDiversity");
  }
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchProjects(
  params: SearchParams,
  searchCriteria: GeneralSearchCriteria,
  projectSearchCriteria: ProjectSearchCriteria,
  aggSearchCriteria: AggSearchCriteria,
  ownerVendorIds: string[],
  addFacets: boolean = true,
): Promise<ResourceSearchResult> {
  let uri: string = "/record/project/" + formatSearchParams(params) + "&resolve-short=true";
  uri += addGeneralSearchCriteria(searchCriteria);
  uri += addAggSearchCriteria(aggSearchCriteria);
  uri += addProjectSearchCriteria(projectSearchCriteria);
  uri += addFieldValueParam("project.ownerVendor", ownerVendorIds); //TODO: Move to projectSearchCriteria.
  if (addFacets) {
    uri += addFacet("client", "project.creatorVendor", "Client");
  }
  /*
    uri += addFieldValueParam('common.status', projectSearchCriteria.statuses);
    uri += addFieldValueParam('project.creatorVendor', projectSearchCriteria.clients);
    uri += addFieldValueParam('project.indigenousAffiliation', projectSearchCriteria.organizations);
    uri += addDateRangeParams(searchCriteria.dateFrom, searchCriteria.dateTo);
    */
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getProjectPath(id: string): Promise<ProjectPath> {
  const response = await publicApi.get("/record/project/id/" + id + "/path");
  return response.data as ProjectPath;
}

export async function getProject(id: string, resolveShort: boolean): Promise<Project> {
  const response = await publicApi.get("/record/project/id/" + id + (resolveShort ? "?resolve-short=true" : ""));
  return response.data as Project;
}

export async function createProject(newProject: Project): Promise<any> {
  const response = await publicApi.post("/record/project", newProject);
  return response.data;
}

export async function updateProject(editedId: string, editedProject: Project): Promise<any> {
  const response = await publicApi.put("/record/project/id/" + editedId, editedProject);
  return response.data;
}

export async function searchProjectVendors(params: SearchParams, searchCriteria: SearchSubProjects, ownerVendorIds: string[] = []): Promise<ResourceSearchResult> {
  /*
    let uri: string = '/record/subproject/' + formatSearchParams(params) + '&resolve-short=true';
    uri += addFieldValueParam('parentProject', searchCriteria.parentProject !== '' ? [ searchCriteria.parentProject as string ] : []);
    uri += addFieldValueParam('ownerVendor', ownerVendorIds);
    const response = await publicApi.get(uri);
    */
  const response = testData.getProjectsVendorsTestData();
  return response as ResourceSearchResult;
}

export async function searchSubProjects(params: SearchParams, searchCriteria: SearchSubProjects, ownerVendorIds: string[] = []): Promise<ResourceSearchResult> {
  let uri: string = "/record/subproject/" + formatSearchParams(params) + "&resolve-short=true";
  uri += addFieldValueParam("parentProject", searchCriteria.parentProject !== "" ? [searchCriteria.parentProject as string] : []);
  uri += addFieldValueParam("ownerVendor", ownerVendorIds);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchChangeOrders(params: SearchParams, searchCriteria: SearchChangeOrders): Promise<ResourceSearchResult> {
  const response = await publicApi.get("/record/expense" + formatSearchParams(params) + "&resolve-short=true" + "&field-value=expense.expenseKind:change-order&field-value=payment.hasProject:" + searchCriteria.project);
  return response.data as ResourceSearchResult;
}

export async function searchPurchaseOrders(params: SearchParams, searchCriteria: SearchPurchaseOrders, resolveShort: boolean = true): Promise<ResourceSearchResult> {
  const response = await publicApi.get(
    "/record/expense" + formatSearchParams(params) + "&resolve-short=" + (resolveShort ? "true" : "false") + "&field-value=expense.expenseKind:purchase-order&field-value=payment.hasProject:" + searchCriteria.project,
  );
  return response.data as ResourceSearchResult;
}

export async function getSpend(id: string): Promise<Spend> {
  const response = await publicApi.get("/record/expense/id/" + id);
  return response.data as Spend;
}

export async function createSpend(newSpend: Spend): Promise<any> {
  const response = await publicApi.post("/record/expense", newSpend);
  return response.data;
}

export async function updateSpend(editedId: string, editedSpend: Spend): Promise<any> {
  const response = await publicApi.put("/record/expense/id/" + editedId, editedSpend);
  return response.data;
}

export async function searchExpenses(params: SearchParams, searchCriteria: GeneralSearchCriteria, expensesSearchCriteria: SearchExpenses, excludeDiversityFacets: boolean = false): Promise<ResourceSearchResult> {
  let uri: string = "/record/expense" + formatSearchParams(params) + "&resolve-short=true";
  uri += addFieldValueParam("expense.expenseClass", expensesSearchCriteria.expenseClasses);
  uri += addFieldValueParam("payment.hasProject", expensesSearchCriteria.projects);
  uri += addFieldValueParam("payment.hasPayee", expensesSearchCriteria.payees);
  uri += addFieldValueParam("payment.hasPayer", expensesSearchCriteria.payers);
  uri += addFieldValueParam("payment.hasBeneficiary", expensesSearchCriteria.beneficiaries);
  uri += addFieldValueParam("expense.expenseKind", expensesSearchCriteria.unsdg);
  if (searchCriteria.locations) {
    uri += addFieldValueParam("properties.location", (searchCriteria.locations as ShortRecord).identifier || "");
  }
  uri += addDateRangeParams(searchCriteria.dateFrom, searchCriteria.dateTo);
  uri += addGeneralSearchCriteria(searchCriteria);
  uri += addFacet("expenseType", "expense.expenseType", "Expense Type");
  uri += addFacet("expenseClass", "expense.expenseClass", "Expense Class");
  uri += addFacet("project", "payment.hasProject", "Project");
  uri += addFacet("location", "properties.location", "Location");
  if (!excludeDiversityFacets) {
    uri += addFacetBundle("_expenseDiversity");
  }
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getExpenseLocations(): Promise<Array<{ value: string; count: number }>> {
  let uri: string = "/record/expense" + formatSearchParams({ page: "1", ipp: "1", sortby: "payment.effectiveDate", sortdir: "asc", terms: "" }) + "&resolve-short=true";
  uri += addFacet("location", "properties.location", "Location");

  const response = await publicApi.get(uri);
  const data = response.data as ResourceSearchResult;

  return data.searchResults.facets.location.values;
}

export async function updateBenefitState(id: string, state: BenefitState, etag: string): Promise<any> {
  const uri: string = "/record/benefit/id/" + id + "/state";
  const headers = { "if-match": etag };
  const config = { headers };
  const response = await publicApi.patch(uri, state, config);
  return response.data;
}

export async function getBenefit(id: string, resolve: string = ""): Promise<Benefit> {
  const response = await publicApi.get("/record/benefit/id/" + id + (resolve !== "" ? "?resolve-short=" + resolve : ""));
  return response.data as Benefit;
}

export async function createBenefit(newProjectBenefit: Benefit): Promise<any> {
  const response = await publicApi.post("/record/benefit", newProjectBenefit);
  return response.data;
}

export async function getVendorBeneficiaryRepsShortList(indigenousCommunityId: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/short?type=vendor&ipp=1000&any-record=true";
  uri += addFieldValueParam("beneficiaryRepFor", [indigenousCommunityId]);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function getVendorShortList(vendorId: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/short?type=vendor&ipp=1";
  uri += addFieldValueParam("identifier", [vendorId]);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

//TODO: Placeholders.
export async function deleteIndigenousEmployment(identifier: string): Promise<any> {
  return true;
}

export async function deleteSpend(identifier: string, etag: string): Promise<any> {
  const uri: string = "/record/spend/id/" + identifier;
  const headers = { "if-match": etag };
  const config = { headers };
  const response = await publicApi.delete(uri, config);
  return response.data;
}

export async function deleteSubProject(identifier: string): Promise<any> {
  return true;
}

export async function deleteProject(identifier: string, etag: string): Promise<any> {
  const uri: string = "/record/project/id/" + identifier;
  const headers = { "if-match": etag };
  const config = { headers };
  const response = await publicApi.delete(uri, config);
  return response.data;
}

export async function deleteVendor(identifier: string, etag: string): Promise<any> {
  const uri: string = "/record/vendor/id/" + identifier;
  const headers = { "if-match": etag };
  const config = { headers };
  const response = await publicApi.delete(uri, config);
  return response.data;
}

export async function deleteExpense(identifier: string, etag: string): Promise<any> {
  const uri: string = "/record/expense/id/" + identifier;
  const headers = { "if-match": etag };
  const config = { headers };
  const response = await publicApi.delete(uri, config);
  return response.data;
}

export async function searchAggregateSeries(searchCriteria: GeneralSearchCriteria, seriesType: string, facetType: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/aggregate/series/" + seriesType + "/by/" + facetType + "?";
  uri += addDateRangeParams(searchCriteria.dateFrom, searchCriteria.dateTo);
  uri += addIdsValueParam(searchCriteria.projects as string[]);
  uri += addIdsValueParam(searchCriteria.organizations as string[]);
  uri += addIdsValueParam(searchCriteria.subVendors as string[]);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchAggregateDrill(searchCriteria: GeneralSearchCriteria, drillPath: string, state: string = "submitted"): Promise<ResourceSearchResult> {
  let uri = `/record/aggregate/drill/benefit?drill-path=${encodeURIComponent(drillPath)}&state=${encodeURIComponent(state)}`;
  if (searchCriteria) {
    uri += addGeneralSearchCriteria(searchCriteria);
  }
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchAggregatesByIdType(id: string, idType: string, aggregateType: string): Promise<ResourceSearchResult> {
  const uri: string = "/record/aggregate/by/" + aggregateType + "/" + idType + "/" + id;
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchExpenseDiversity(searchCriteria: GeneralSearchCriteria, vendorId: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/aggregate/vendor/id/" + vendorId + "/expense/diversity?";
  if (searchCriteria) {
    uri += addGeneralSearchCriteria(searchCriteria);
  }
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchAggregateProcurementByType(searchCriteria: GeneralSearchCriteria, procurementType: string, aggSearchCriteria: AggSearchCriteria | null = null): Promise<ResourceSearchResult> {
  let uri: string = "/record/aggregate/procurement/by/" + procurementType;
  uri += "?ipp=999999";
  if (searchCriteria) {
    uri += addGeneralSearchCriteria(searchCriteria);
  }
  if (aggSearchCriteria) {
    uri += addAggSearchCriteria(aggSearchCriteria);
  }
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchAggregatesByRelation(
  searchCriteria: GeneralSearchCriteria,
  relationType: string,
  vendorId: string,
  limitTo: string = "",
  aggSearchCriteria: AggSearchCriteria | null = null,
): Promise<ResourceSearchResult> {
  let uri: string = "/record/aggregate/summary/by/" + relationType + "/" + vendorId;
  // Add a large ipp value to ensure the results are correct - Workaround due to bug in API default paging.
  uri += "?ipp=999999";
  if (searchCriteria) {
    uri += addGeneralSearchCriteria(searchCriteria);
  }
  if (aggSearchCriteria) {
    uri += addAggSearchCriteria(aggSearchCriteria);
  }
  uri += limitTo !== "" ? "&limit-to=" + limitTo : "";
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchAggregates(searchCriteria: GeneralSearchCriteria, aggregateType: string, partnersOnly: boolean = false, clientsOf: string[] = []): Promise<ResourceSearchResult> {
  let uri: string = "/record/aggregate/by/" + aggregateType + "?";
  switch (aggregateType) {
    case "project":
      uri += addIdsValueParam(searchCriteria.projects as string[]);
      break;
    case "beneficiary":
      uri += addIdsValueParam(searchCriteria.organizations as string[]);
      uri += addNamedArrayParam(searchCriteria.projects, "project");
      break;
    case "payer":
      uri += addIdsValueParam(searchCriteria.subVendors as string[]);
      uri += addNamedArrayParam(clientsOf, "clients-of");
      break;
    default:
  }
  if (partnersOnly) {
    uri += "&partners-only=true";
  }
  uri += addDateRangeParams(searchCriteria.dateFrom, searchCriteria.dateTo);
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function searchAggregatesById(searchCriteria: GeneralSearchCriteria, aggSearchCriteria: AggSearchCriteria | null = null, id: string): Promise<ResourceSearchResult> {
  let uri: string = "/record/aggregate/id/" + id + "?";
  if (searchCriteria) {
    uri += addGeneralSearchCriteria(searchCriteria);
  }
  if (aggSearchCriteria) {
    uri += addAggSearchCriteria(aggSearchCriteria);
  }
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function forgotPasswordEmail(userId: string): Promise<any> {
  const body = {
    passwordOperation: {
      action: "forgotPassword",
      userId,
    },
  };
  const response = await publicApi.post("/record/user/password", body);
  return response.data;
}

export async function changePassword(confirmationCode: string, userId: string, newPassword: string): Promise<any> {
  const body = {
    passwordOperation: {
      action: "confirmForgotPassword",
      userId,
      confirmationCode,
      newPassword,
    },
  };
  const response = await publicApi.post("/record/user/password", body);
  return response.data;
}

// TODO: Create a bulk import response data model.
export async function importBulkRecords(records: any[], type: string): Promise<any> {
  const body: any = {
    records: records.map<any>((record) => ({ type, record })),
  };
  const response = await publicApi.post("/record/bulk", body, { params: { "upsert-ok": "true" } });
  return response.status === 202 ? response.headers["x-ws-location"] : "";
}

export async function getBulkImportStatus(id: string): Promise<any> {
  const uri: string = "/record/bulk/id/" + id + "/status";
  const response = await publicApi.get(uri);
  return response.data;
}

export async function getSchemasByType(schemaType: string): Promise<any> {
  const response = await publicApi.get("/schema/type/" + schemaType);
  return response.data;
}

export async function getUserAccountSettings(): Promise<any> {
  /*
    const response = await publicApi.get('/schema/type/' + schemaType);
    return response.data;
    */
  return {};
}

export async function updateUserAccountSettings(editedUserAccountSettings: UserAccountSettings): Promise<any> {
  /*
    const response = await publicApi.put('/record/account-settings', editedUserAccountSettings);
    return response.data;
    */
  return {};
}

export async function createReportPdf(reportData: any): Promise<any> {
  const uri: string = "/report/generate";
  const encodedReportData = base64Encode(JSON.stringify(reportData));
  const response = await reportsApi({
    url: uri,
    method: "POST",
    data: encodedReportData,
    responseType: "blob",
  });
  return response.data;
}

function getNistoProfilesUri(dataType: string) {
  const baseUri: string = "/nisto-profiles/version-test/api/1.1/obj/";
  return baseUri + dataType + "?";
}

export async function searchNistoProfiles(searchTerms: string): Promise<ResourceSearchResult> {
  const uri: string = "/nisto-profiles/profile?term=" + searchTerms;
  const response = await publicApi.get(uri);
  return response.data as ResourceSearchResult;
}

export async function connectProfile(profileId: string, vendorId: string, etag: string): Promise<any> {
  const uri: string = "/record/vendor/id/" + vendorId + "/nisto-profiles/profile/" + profileId;
  const headers = { "if-match": '"' + etag + '"', "Content-Type": "application/json" };
  const config = { headers };
  const response = await publicApi.put(uri, null, config);
  return response.data;
}

export async function disconnectProfile(vendorId: string, etag: string): Promise<any> {
  const uri: string = "/record/vendor/id/" + vendorId + "/nisto-profiles/profile";
  const headers = { "if-match": etag };
  const config = { headers };
  const response = await publicApi.delete(uri, config);
  return response.data;
}

export async function getReportMatrix(searchCriteria: GeneralSearchCriteria, tenant: string, id: string, row: string, col: string, hourly = false, project: string | null = null): Promise<EIRData> {
  const crossAnalysisUri = "/analysis/employment/cross";
  const employmentAnalysisUri = "/analysis/employment";
  const headers = { "X-NistoCurrOrg": id };
  const params: { [key: string]: string[] | string } = { q: [] };
  params.column = col.replace("$", "");
  params.row = row.replace("$", "");

  if (hourly) {
    (params.q as string[]).push("expense.expenseKind;hourly-wages");
  }
  if (project) {
    (params.q as string[]).push(`payment.hasProject;${project}`);
  }
  if (searchCriteria.dateFrom) {
    params["date-from"] = searchCriteria.dateFrom;
  }

  if (searchCriteria.dateTo) {
    params["date-to"] = searchCriteria.dateTo;
  }

  // Cross analysis
  const crossAnalysisData = (
    await dataApi.get(crossAnalysisUri, {
      params,
      headers,
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "repeat" });
      },
    })
  ).data[0].payload;
  const crossAnalysis = crossAnalysisData.cross_analysis as EIRCrossAnalysis[];
  // const crossAnalysisHeadcount = crossAnalysisData.headcount as any[];

  // Employment analysis
  const employmentAnalysis = (
    await dataApi.get(employmentAnalysisUri, {
      params,
      headers,
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "repeat" });
      },
    })
  ).data.employeeAnalysis as EIREmployeeAnalysis[];

  const parsedEmployment: EIREmployeeAnalysis[] = [];
  for (const ea of employmentAnalysis) {
    const parsedColumns: any[] = [];
    for (const c of ea.columns) {
      let value = c.column_value;
      if (Array.isArray(c.column_value)) {
        if (c.column_value.length > 0) {
          value = c.column_value.pop();
        } else {
          value = "";
        }
      }
      parsedColumns.push({
        ...c,
        column_value: value || "",
      });
    }
    parsedEmployment.push({
      ...ea,
      columns: parsedColumns,
    });
  }

  // Headcount
  // const allCodes: string[] = crossAnalysis.flatMap((entry) => entry.columns.flatMap((column) => column.codes));
  // const uniqueCodes = new Set(allCodes);
  // const headCount = uniqueCodes.size;

  return {
    reportMeta: {
      dataset: tenant,
      id,
      isProject: project ? true : false,
      row,
      column: col,
      effectiveToDate: searchCriteria.dateTo,
    },
    totals: {
      // headcount: [{ total: headCount }].concat(crossAnalysisHeadcount),
      employeeAnalysis: parsedEmployment,
      cross_analysis: crossAnalysis,
    },
  } as EIRData;
}

export async function getExpenseAggregates(query: DataServiceAggregateQuery, organization: string): Promise<ExpenseAggregate[]> {
  let uri = "/aggregate/expense?";
  uri += addDSQueryParams(query);

  const func = async () => {
    return await dataApi.get(uri, { headers: { "X-NistoCurrOrg": organization } });
  };

  const response = await retry(func, 3);
  return response.data as ExpenseAggregate[];
}

export async function getEmploymentAggregates(query: DataServiceAggregateQuery, organization: string): Promise<EmploymentAggregate[]> {
  let uri = "/aggregate/employment?";
  uri += addDSQueryParams(query);

  const func = async () => {
    return await dataApi.get(uri, { headers: { "X-NistoCurrOrg": organization } });
  };

  const response = await retry(func, 3);
  return response.data as EmploymentAggregate[];
}

export async function getEmploymentAnalysis(query: DataServiceAggregateQuery, organization: string): Promise<EIREmployeeAnalysis[]> {
  let uri = "/analysis/employment?";
  uri += addDSQueryParams(query);

  const func = async () => {
    return await dataApi.get(uri, { headers: { "X-NistoCurrOrg": organization } });
  };

  const response = await retry(func, 3);
  return response.data.employeeAnalysis as EIREmployeeAnalysis[];
}

export async function getEmploymentHeadcount(query: DataServiceAggregateQuery, organization: string) {
  let uri = "/analysis/employment/headcount?";
  uri += addDSQueryParams(query);

  const func = async () => {
    return await dataApi.get(uri, { headers: { "X-NistoCurrOrg": organization } });
  };

  const response = await retry(func, 3);
  return response.data[0].payload;
}

function retry(fn: () => Promise<any>, retries = 3, err = null) {
  if (!retries) {
    return Promise.reject(err);
  }
  return fn().catch((error) => {
    return retry(fn, retries - 1, error);
  });
}
